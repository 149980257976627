/*eslint-disable*/
import React from "react"
// react components for routing our app without refresh
import { Link } from "gatsby"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

// @material-ui/icons
// import { Apps, CloudDownload } from "@material-ui/icons";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx"

function HeaderLinks({ ...props }) {
  const { classes } = props
  return (
    <List className={classes.list} style={{ color: "#123E78" }}>
      <ListItem className={classes.listItem}>
          <Link
            to="/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            Home
          </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
          <Link
            to="/contact"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            Contact
          </Link>
      </ListItem>

      {/* <ListItem className={classes.listItem}> */}
      {/* speak to chris about exposing this */}
        {/* <Tooltip
          id="news"
          title="News"
          placement={
            typeof window !== "undefined" && window.innerWidth > 959
              ? "top"
              : "left"
          }
          classes={{ tooltip: classes.tooltip }}
        >
          <Link
            to="/news"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            News
          </Link>
        </Tooltip>
      </ListItem> */}
      
    </List>
  )
}

export default withStyles(headerLinksStyle)(HeaderLinks)
